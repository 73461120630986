import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { SecondaryCta } from '@clearscore/ui.shared.markdown-shortcodes';

import type { TargettedEvent } from '../../hooks/tracking';
import useWebsiteTracking from '../../hooks/tracking';

interface MDXAnchorProps {
    mdxType?: 'a';
    href?: string;
    children?: string;
}

type MaybeMDXAnchor = ReactElement<MDXAnchorProps>;

type NestedChildrenReactElement = ReactElement<{ children: MaybeMDXAnchor }>;

// get nested props from mdx children
// so we can support bold / italic e.g tags in the markdown
const getNestedChildrenProps = (child: ReactNode) =>
    React.isValidElement(child) ? (child as NestedChildrenReactElement)?.props?.children?.props : {};

const getFirstHrefFromChildren = (children: MaybeMDXAnchor): string => {
    const hrefs = React.Children.map<string, MaybeMDXAnchor>(children, (child) => {
        if (React.isValidElement(child) && child?.props?.mdxType === 'a' && child?.props?.href) return child.props.href;
        return (getNestedChildrenProps(child) as MDXAnchorProps)?.href || '';
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return hrefs?.filter(Boolean)[0] ?? '';
};

// get all children text (including the link text)
const getCopyFromChildren = (children: ReactNode): string =>
    React.Children.map(children, (child) => {
        if (typeof child === 'string') return child;
        return (getNestedChildrenProps(child) as MDXAnchorProps)?.children;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    })?.join('') ?? '';

interface IArticleSecondaryCtaProps {
    children: ReactNode;
}

const ArticleSecondaryCta = ({ children }: IArticleSecondaryCtaProps): ReactElement => {
    const { trackCtaClick } = useWebsiteTracking();
    const mdxChildren = React.isValidElement(children)
        ? (children as NestedChildrenReactElement).props.children
        : children;
    const href = getFirstHrefFromChildren(mdxChildren as MaybeMDXAnchor);
    const copy = getCopyFromChildren(mdxChildren);

    return (
        <SecondaryCta
            onClick={(e): void => {
                const { target } = e.nativeEvent;

                // Exit early because the user is clicking a link inside this component,
                // rather than the ArticleSecondaryCta itself
                if (target !== e.currentTarget && target instanceof HTMLAnchorElement) {
                    return;
                }

                trackCtaClick({
                    e: e as unknown as TargettedEvent,
                    ctaType: 'secondary',
                    url: href,
                    copy,
                });
            }}
        >
            {children}
        </SecondaryCta>
    );
};

export default ArticleSecondaryCta;
