import type { ReactElement, ReactNode, PropsWithChildren } from 'react';
import React from 'react';
import cx from 'classnames';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Link from 'gatsby-link';
import { MDXProvider } from '@mdx-js/react';
import Text from '@clearscore/ui.rainbow.text';
import Template from '@clearscore/shared.website-template';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';
import ArticleTools from '@clearscore/shared.website-article-tools';
import type { IAppStoreConfig } from '@clearscore/shared.website-app-logos';

import ScrollProgressBar from './components/scroll-progress-read';
import Hr from './components/hr';
import ArticlePrimaryCta from './components/article-primary-cta';
import ArticleSecondaryCta from './components/article-secondary-cta';
import TableOfContents from './components/table-of-contents';
import Highlights from './components/article-highlights';
import Author from './components/author-bio';
import styles from './learn.module.css';
import spacing from './spacing.module.css';
import type { TargettedEvent } from './hooks/tracking';
import useWebsiteTracking from './hooks/tracking';

type TTrackLinkClick = ReturnType<typeof useWebsiteTracking>['trackLinkClick'];

interface IPageConfig {
    tags?: string[];
    wordCount?: number;
    timeToRead: number;
    displayDescription?: boolean;
    thumbnail: string;
    hero: string;
    displayToc?: boolean;
    ctaUrl: string;
    primaryCategory?: string;
    primaryCategoryUrl: string;
    tableOfContents: {
        items?: Array<{
            title: string;
            url: string;
        }>;
    };
}

interface IAuthor {
    name: string;
    title: string;
    bio: string;
    image: IGatsbyImageData;
    formattedPublishDate: string;
    publishDate: string;
    publishedDate: string;
}

interface IMarkdownOverrideProps {
    href: string;
    children: string;
}

const getMarkdownOverrides = ({ trackLinkClick }: { trackLinkClick: TTrackLinkClick }) => ({
    a({ children, ...rest }: PropsWithChildren<IMarkdownOverrideProps>) {
        const track = (e: TargettedEvent): void =>
            trackLinkClick({
                e,
                url: rest.href,
                copy: children,
            });

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ShortCodes.a onClick={track} {...rest}>
                {children}
            </ShortCodes.a>
        );
    },
    h2({ children, ...rest }: { children: string }): ReactElement {
        return (
            <div className={styles.navOffset}>
                <ShortCodes.h2 {...rest}>{children}</ShortCodes.h2>
            </div>
        );
    },
});

interface ICtaProps {
    language: Record<string, string>;
    pageConfig: IPageConfig;
}

const Cta = ({ language, pageConfig }: ICtaProps): ReactElement => {
    const { trackCtaClick } = useWebsiteTracking();
    return (
        <ArticlePrimaryCta
            title={language.ctaTitle}
            blurb={language.ctaBlurb}
            cta={language.ctaButtonCopy}
            url={pageConfig.ctaUrl}
            onClick={(e: TargettedEvent): void => {
                trackCtaClick({
                    e,
                    ctaType: 'primary',
                    description: language.ctaBlurb,
                    copy: language.ctaButtonCopy,
                    url: pageConfig.ctaUrl,
                    title: language.ctaTitle,
                });
            }}
        />
    );
};

interface ICategoryHeaderProps {
    to: string;
    children: ReactNode;
    isWebView: boolean;
}

const CategoryHeader = ({ children = '', to, isWebView }: ICategoryHeaderProps): ReactElement => {
    const Header = (
        <Text.H6 tag={Text.tags.P} dataId="article-category">
            {children}
        </Text.H6>
    );

    return <div className={styles.categoryHeader}>{to && !isWebView ? <Link to={to}>{Header}</Link> : Header}</div>;
};

interface IArticle {
    language: Record<string, string>;
    targetRef: React.RefObject<HTMLDivElement | null>;
    pageConfig: IPageConfig;
    isWebView: boolean;
    author: IAuthor;
    hero: {
        image: IGatsbyImageData;
        language?: Record<string, string>;
    };
    allHighlights: string[];
}

const Article = ({
    language,
    targetRef,
    pageConfig,
    author,
    hero,
    allHighlights,
    isWebView,
}: IArticle): ReactElement => {
    const { trackCtaClick } = useWebsiteTracking();
    return (
        <article data-layout="full-bleed" className={styles.article}>
            <Template.Grid>
                {pageConfig.displayToc && (
                    <div data-layout="article-left" className={styles.primaryCta}>
                        <TableOfContents {...pageConfig} data-qa="table-of-contents-desktop" />
                    </div>
                )}
                <div data-layout="article-right" className={styles.primaryCta}>
                    <ArticlePrimaryCta
                        title={language.ctaTitle}
                        blurb={language.ctaBlurb}
                        cta={language.ctaButtonCopy}
                        url={pageConfig.ctaUrl}
                        onClick={(e: React.MouseEvent<Element, MouseEvent>): void => {
                            trackCtaClick({
                                e,
                                ctaType: 'primary',
                                description: language.ctaBlurb,
                                copy: language.ctaButtonCopy,
                                url: pageConfig.ctaUrl,
                                title: language.ctaTitle,
                            });
                        }}
                    />
                </div>
                <div data-layout="article-content" className={styles.content} ref={targetRef}>
                    <div data-layout="article-content">
                        <CategoryHeader to={pageConfig.primaryCategoryUrl} isWebView={isWebView}>
                            {pageConfig.primaryCategory}
                        </CategoryHeader>
                        <div className={cx(spacing.medium, styles.pageTitle)} data-qa="pageTitle">
                            <Text.H1>{language.heading || language.title}</Text.H1>
                        </div>
                        {pageConfig.displayDescription && (
                            <div className={cx(spacing.small, styles.pageDescription)} data-qa="pageDescription">
                                <Text.H2 tag={Text.tags.P} type={Text.types.SMALL}>
                                    {language.description}
                                </Text.H2>
                            </div>
                        )}
                        <div className={cx(spacing.big)}>
                            <Author.Summary timeToRead={pageConfig.timeToRead} {...author} />
                        </div>
                        {hero && hero.image && (
                            <figure className={cx(spacing.super, styles.heroImage)} data-qa="heroImage">
                                <GatsbyImage
                                    className={spacing.tiny}
                                    image={hero.image}
                                    alt={hero.language?.altText ?? ''}
                                    title={hero.language?.altText}
                                />
                                <footer>
                                    <Text.Caption tag="span">{hero.language?.copyright}</Text.Caption>
                                </footer>
                            </figure>
                        )}
                        {pageConfig.displayToc && (
                            <div className={cx(spacing.big, styles.mobilePrimaryCta)}>
                                <div className={spacing.big}>
                                    <TableOfContents {...pageConfig} data-qa="table-of-contents-mobile" />
                                </div>
                            </div>
                        )}
                        <div className={cx(spacing.big, styles.mobilePrimaryCta)}>
                            <Hr spacing={Hr.spacings.BIG} />
                            <div className={spacing.big}>
                                <Cta pageConfig={pageConfig} language={language} />
                            </div>
                            <Hr spacing={Hr.spacings.BIG} />
                        </div>
                        {language.content && (
                            <div
                                className={spacing.super}
                                data-qa="markdown"
                                data-id="markdown"
                                data-layout="article-content"
                            >
                                <MDXRenderer>{language.content}</MDXRenderer>
                            </div>
                        )}
                    </div>

                    <div data-layout="article-content">
                        <div className={cx(spacing.super, styles.highlights)}>
                            <Highlights heading={language.keyHighlightsTitle}>
                                {allHighlights.map((highlight) => (
                                    <Highlights.Item key={highlight}>{highlight}</Highlights.Item>
                                ))}
                            </Highlights>
                        </div>
                        <div className={styles.contentPadding}>
                            <Hr spacing={Hr.spacings.NONE} />
                        </div>
                        <div className={spacing.super} data-qa="authorFooter">
                            <Author isArticleFooter {...author} />
                        </div>
                    </div>
                </div>
            </Template.Grid>
        </article>
    );
};

const getValidKeyHighlights = (keyHighlights = ''): string[] => {
    const splitRegEx = /\n/gm;
    const allHighlights = keyHighlights.split(splitRegEx);
    // legacy articles were migrated as html lists - we need to strip out the <ul> and <li> tags
    // TODO: migration should be added to strip html out of the data
    const stripOutHTMLRegEx = /(<([^>]+)>)/g;
    return allHighlights.map((highlight) => highlight.replace(stripOutHTMLRegEx, '')).filter((value) => value !== '');
};

interface ICookiePolicyConfig {
    moreInfoLinkUrl: string;
    enabled: boolean;
    language: Record<string, string>;
}

interface IHero {
    name: string;
    title: string;
    bio: string;
    image: IGatsbyImageData;
}

export interface ILearnProps {
    appStoreConfig: IAppStoreConfig;
    cookiePolicyConfig: ICookiePolicyConfig;
    language: Record<string, string>;
    pageConfig: IPageConfig;
    author: IAuthor;
    hero: IHero;
    isWebView?: boolean;
}

const Learn = ({
    appStoreConfig,
    cookiePolicyConfig,
    language,
    pageConfig,
    author,
    hero,
    isWebView = false,
}: ILearnProps): ReactElement => {
    const targetRef = React.createRef<HTMLDivElement>();
    const keyHighlights = getValidKeyHighlights(language.keyHighlights);
    const { trackLinkClick } = useWebsiteTracking();
    const markdownOverrides = getMarkdownOverrides({ trackLinkClick });
    const mdxComponents = {
        ...ShortCodes,
        ...ArticleTools,
        ArticleCta: ArticleSecondaryCta,
        ...markdownOverrides,
    };
    return (
        <MDXProvider components={mdxComponents}>
            {isWebView ? (
                <Article
                    isWebView={isWebView}
                    language={language}
                    targetRef={targetRef}
                    pageConfig={pageConfig}
                    author={author}
                    hero={hero}
                    allHighlights={keyHighlights}
                />
            ) : (
                <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
                    <div className={styles.readingProgressBar}>
                        <ScrollProgressBar target={targetRef} />
                    </div>
                    <Article
                        isWebView={isWebView}
                        language={language}
                        targetRef={targetRef}
                        pageConfig={pageConfig}
                        author={author}
                        hero={hero}
                        allHighlights={keyHighlights}
                    />
                </Template>
            )}
        </MDXProvider>
    );
};

export default Learn;
